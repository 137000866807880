import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.hencok';

@Injectable({
  providedIn: 'root'
})
export class TramitesService {

 


  protected resourceUrl = `${environment.API_URL}/tramite`;

  constructor(
    protected http: HttpClient
  ) { }

  getTramites() {
    return this.http.get(`${this.resourceUrl}/getTramites`);
  }

  getTramitesRealizadosFilter(request: any, sort){
    return this.http.post(`${this.resourceUrl}/filter`, {request, sort});
  }

  checkSigned(){
    return this.http.get(`${this.resourceUrl}/checkDocumentsSigned`);
  }
  saveDocumentos(id: number,idTipoTramite:Number, documento): Observable<any> {
    return this.http.post(`${this.resourceUrl}/saveDocuments`, { id,idTipoTramite,documento });
  }
  getDocumentos(idNextcloud:Number): Observable<any> {
    return this.http.post(`${this.resourceUrl}/getDocuments`, {idNextcloud});
  }
  changeStateDocumento(idNextcloud: any, idEstado: any) {
    return this.http.post(`${this.resourceUrl}/changeStateDocuments`, {idNextcloud,idEstado});
  }
  changeStateTramite(tipoTramite: any, idTramite: any) {
    return this.http.post(`${this.resourceUrl}/changeStateTramite`, {tipoTramite,idTramite});
  }
  deleteDocumento(idNextcloud: any) {
    return this.http.post(`${this.resourceUrl}/deleteDocumento`, {idNextcloud});
  }
  vincularPresu(idTramite: any, tipoTramite: any, idPresu: any) {
    return this.http.post(`${this.resourceUrl}/vincularTramiteAPresupuesto`, {idTramite,tipoTramite,idPresu});
  }
  download(tipoTramite: any,idTramite) {
    return this.http.post(`${this.resourceUrl}/downloadTramite`,{tipoTramite,idTramite},{responseType: 'arraybuffer'});
  }
  getListadoTramites(){
    return this.http.get(`${this.resourceUrl}/listadoDeTramites`);
  }
  generateExcel(){
    return this.http.get(`${this.resourceUrl}/generateExcel`);
  }
  getPreciosTramite(id: number, provincia: any){
    return this.http.post(`${this.resourceUrl}/getTramitePrice/${id}`, {provincia: provincia});
  }
  getPreciosTramiteList(provincia: any){
    return this.http.post(`${this.resourceUrl}/getTramitePriceList`, {provincia: provincia});
  }
  extractComission(id: number, tramiteId: any){
    return this.http.post(`${this.resourceUrl}/extractComission`, {id: id, tramites: tramiteId});
  }
  generateComissionExcel(rol: any, tramites: any){
    return this.http.post(`${this.resourceUrl}/generateComissionExcel`, {rol: rol, tramites: tramites});
  }
  checkStateFirma(idTramite: number, tipoTramite: number, tipoDocumento: number){
    return this.http.post(`${this.resourceUrl}/checkStateFirma`, { idTramite: idTramite, tipoTramite: tipoTramite, tipoDocumento: tipoDocumento });
  }
}
