<h3 class="full-form-group" ><b>Contacto-</b> Envia una notificación a tu gestor</h3>
<hr class="linea-divisoria-title">
<form name="form" (ngSubmit)="onSubmit2() " [formGroup]="form" novalidate method="post" enctype="multipart/form-data"
class=" full-form-group ">
<div class="row">
    <div class="col-12 rowForm">
        <label class="crear-editar-form">Asunto</label>
        <input type="text" placeholder="Escribe tu asunto"  class="form-control mt-1" name="asunto"
            formControlName="asunto"
            />
    </div>
    <div class="col-12 rowForm">
        <label class="crear-editar-form">Mensaje</label>
        <textarea  name="cuerpo" rows=3 cols=20 
        placeholder="Escriba aquí su mensaje" class="form-control mt-1 mr-2" formControlName="cuerpo"  style="resize: none;"></textarea>
    </div>
    <div class="col-12 rowForm d-flex justify-content-end">
        
        <button class="btn btn-outline-primary mr-3" type="submit">Enviar Notificación</button> 
        <button (click)="sendTramite()" class="btn btn-primary" type="button">Enviar Trámite</button>    
    </div>
</div>
    
</form>