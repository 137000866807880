<div class="pageTitle">
    <h1 *ngIf="!isUpdated" class="m-0">Nuevo canje del permiso</h1>
    <h1 *ngIf="isUpdated" class="m-0">Editar canje del permiso</h1>
</div>
<div>
    <hr class="linea-divisoria-title">
</div>

    <form [formGroup]=" form" class="flexn">
        <div class="firstFormTitle">
            <label class="formTitleText">Comprobar convenio</label>
        </div>
        <div class="formGroup bgLightGreen">
            <!-- <div class="rowForm">
                <div class="row">
                    <div class="col-sm-12">
                        <label class="textLabel">Uno de los requisitos para canjear un carnet de conducir extranjero en España, es que dicho carnet sea anterior al
                            convenio bilateral entre España y el resto de países, y que permiten esa convalidación de carnet.</label>
                    </div>
                </div>
            </div> -->
            <div class="rowForm">
                <div class="row">
                    <div class="col-sm-3">
                        <label class="crear-editar-form requerido">País de carnet</label>
                        <select (change)="showMoreInfo($event)" class="form-control" formControlName="paisConvenio"
                        [ngClass]="{'is-invalid': isRequired && this.form.controls['paisConvenio'].errors}">
                            <option selected value="null">Selecciona el país</option>
                            <option *ngFor="let convenio of ListConvenios" [value]="convenio.id">
                                {{ convenio.pais }}
                            </option>
                        </select>
                    </div>
        <!--             <div class="col-sm-3">
                        <label class="crear-editar-form">Fecha expedición</label>
                        <input type="date" class="form-control" (change)="isChecked=false" [(ngModel)]="selectedDate">
                    </div>
                    <div class="button-box">
                        <button type="button" class="btn btn-primary pl-3" (click)="checkDate() " type="submit">Comprobar</button>
                    </div> -->
                </div>
            </div>
            <div class="rowForm" *ngIf="convenioSelected && selectedDate && !canjeable && isChecked">
                <div class="row">
                    <div class="col-sm-12">
                        <textarea class="form-control outlinenone" disabled
                                style="resize: none;">No se puede hacer canje del carnet, su fecha de expedición es posterior a la fecha del convenio bilateral entre España y {{convenioSelected.pais}}</textarea>
                    </div>
                </div>
            </div>
            <div class="rowForm" *ngIf="canjeable && isChecked">
                <div class="row">
                    <div class="col-sm-12">
                        <label class="crear-editar-form requerido">Notas y requisitos extra</label>
                        <textarea class="form-control outlinenone" disabled
                                style="resize: none;">{{convenioSelected.notasAdicionales}}</textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="formTitle" *ngIf="canjeable && isChecked">
            <label class="formTitleText">Datos solicitante</label>
        </div>
        <div class="formGroup bgLightGreen" *ngIf="canjeable && isChecked">


        <div class="rowForm">
            <div class="row">
                <div class="col-sm-3">
                    <label class="crear-editar-form requerido">Tipo de persona</label>
                    <input class="form-control" [value]="this.listTipo[1]['nombre']" disabled>
                </div>
                <div class="col-sm-6" *ngIf="isEmpresa">
                    <label class="crear-editar-form requerido">Nombre empresa</label>
                    <input class="form-control" type="text" id="nombre_empresa" formControlName="nombre_empresa"
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['nombre_empresa'].errors}">
                </div>
                
                <div class="col-sm-3" *ngIf="isEmpresa">
                    <label class="crear-editar-form requerido">CIF empresa</label>
                    <input class="form-control" type="text" id="cif_empresa" formControlName="cif_empresa"
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['cif_empresa'].errors}">
                </div>
                <div class="col-sm-3" *ngIf="!isEmpresa">
                    <label class="crear-editar-form requerido">Nombre </label>
                    <input class="form-control" type="text" formControlName="nombre"
                   [ngClass]="{'is-invalid': isRequired && this.form.controls['nombre'].errors}">
                </div>
                <div class="col-sm-3" *ngIf="!isEmpresa">
                    <label class="crear-editar-form requerido">Primer apellido</label>
                    <input class="form-control" type="text" formControlName="apellido1"
                   [ngClass]="{'is-invalid': isRequired && this.form.controls['apellido1'].errors}">
                </div>
                <div class="col-sm-3" *ngIf="!isEmpresa">
                    <label class="crear-editar-form requerido">Segundo apellido</label>
                        <input class="form-control" type="text" formControlName="apellido2"
                        [ngClass]="{'is-invalid': isRequired && this.form.controls['apellido2'].errors}">
                </div>   
            </div>
      
        </div>
    
        <div class="rowForm">
            <div class="row">
                <!--<label class="crear-editar-form requerido col-sm-6">Tipo documento*</label>-->
                <div class="col-sm-4">
                    <label class="crear-editar-form requerido">Fecha de nacimiento</label>
                    <input type="date" class="form-control" name="fecha_nacimiento" formControlName="fecha_nacimiento"
                    minlength="3" maxlength="20"
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['fecha_nacimiento'].value == ''}" />
                </div>
                <div class="col-sm-4">
                    <label class="crear-editar-form requerido">NIE</label>
                    <input class="form-control" type="text" id="numero_documento" formControlName="numero_documento"
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['numero_documento'].errors}">
                </div>
                <div class="col-sm-4">
                    <label class="crear-editar-form requerido">Fecha validez NIE</label>
                    <input type="date" class="form-control" name="validez_dni" formControlName="validez_dni" minlength="3"
                    maxlength="20"
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['validez_dni'].value == ''}" />
                </div>
                
            </div>
     
        </div>

        <div class="rowForm" *ngIf="isEmpresa">
            <div class="row">
                <!--<label class="crear-editar-form requerido col-sm-6">Tipo documento*</label>-->
                <div class="col-sm-3">
                    <label class="crear-editar-form requerido">Nombre administrador</label>
                    <input class="form-control" type="text" formControlName="nombre"
                   [ngClass]="{'is-invalid': isRequired && this.form.controls['nombre'].errors}">
                </div>
                <div class="col-sm-3">
                    <label class="crear-editar-form requerido">Primer apellido administrador</label>
                    <input class="form-control" type="text" formControlName="apellido1"
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['apellido1'].errors}">
                </div>
                <div class="col-sm-3">
                    <label class="crear-editar-form requerido">Segundo apellido administrador</label>
                    <input class="form-control" type="text" formControlName="apellido2"
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['apellido2'].errors}">
                </div>
                <div class="col-sm-3">
                    <label class="crear-editar-form requerido">NIF/NIE administrador</label>
                    <input class="form-control" type="text" id="numero_documento" formControlName="numero_documento"
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['numero_documento'].errors}">
                </div>
                
            </div>
     
        </div>
    
        <div class="rowForm">
            <div class="row">
                <div class="col-sm-6">
                    <label class="crear-editar-form requerido">Móvil</label>
                <input class="form-control outlinenone" type="text" mask="9{9}" id="telefono" formControlName="telefono"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['telefono'].errors}">
                </div>
                <div class="col-sm-6">
                    <label class="crear-editar-form requerido">Correo electrónico</label>
                    <input type="email" name="email" class="form-control" formControlName="email"
                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['email'].errors}" />
                </div>
                </div>
            </div>
        
                <div class="rowForm">
                    <div class="row">
                        <div class="col-sm-3">
                            <label class="crear-editar-form requerido">Tipo de vía</label>
                            <select formControlName="tipo_via" class="form-control"
                            [ngClass]="{'is-invalid': isRequired && this.form.controls['tipo_via'].errors}">
                                <option *ngFor="let vias of ListVias" [value]="vias.id">
                                    {{ vias.tipovianormalizado }}
                                </option>
                            </select>
                        </div>
                        
                        <div class="col-sm-3">
                            <label class="crear-editar-form requerido">Dirección</label>
                                <input type="text" formControlName="nombre_via" class="form-control"
                                [ngClass]="{'is-invalid': isRequired && this.form.controls['nombre_via'].errors}" />
                        </div>
                        
        
                        <div class="col-sm-3">
                            <label class="crear-editar-form requerido">Nº de la vía</label>
                            <input type="text" mask="9{5}" formControlName="numero_via" class="form-control"
                            [ngClass]="{'is-invalid': isRequired && this.form.controls['numero_via'].errors}" />
                        </div>
                        
                        <div class="col-sm-3">
                            <label class="crear-editar-form">Bloque</label>
                            <input type="text" formControlName="bloque" class="form-control" />
                        </div>
                        
                    </div>
                
                </div>
                <div class="rowForm">
                <div class="row ">
                <div class="col-sm-3">
                    <label class="crear-editar-form">Escalera</label>
                    <input type="text" formControlName="escalera" class="form-control" />
                </div>
                <div class="col-sm-3">
                    <label class="crear-editar-form">Planta</label>
                        <input type="text" formControlName="piso" class="form-control" />
                </div>
                <div class="col-sm-3">
                    <label class="crear-editar-form">Puerta</label>
                    <input type="text" formControlName="puerta" class="form-control" />
                </div>
                
                <div class="col-sm-3">
                    <label class="crear-editar-form">Km</label>
                    <input type="text" formControlName="km" class="form-control" />
                </div>
                
            </div>
        </div>
        
        <div class="rowForm">
            <div class="row">
                <div class="col-sm-3">
                    <label class="crear-editar-form requerido">Comunidad autónoma</label>
                    <select formControlName="ccaa" class="form-control" (change)="onChangeProvincia($event)"
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['ccaa'].errors}">
                        <option *ngFor="let ccaa of ListComunidades" [value]="ccaa.id">
                            {{ ccaa.nombre }}
                        </option>
                    </select>
                </div>
                
                <div class="col-sm-3">
                    <label class="crear-editar-form requerido">Provincia</label>
                    <select formControlName="provincia" class="form-control" (change)="onChangeMunicipio($event)"
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['provincia'].errors}">
                        <option *ngFor="let provincia of ListProvincias" [value]="provincia.id">
                            {{ provincia.nombre }}
                        </option>
                    </select>
                </div>

                <div class="col-sm-3">
                    <label class="crear-editar-form requerido">Municipio</label>
                    <select formControlName="municipio" class="form-control"
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['municipio'].errors}">
                        <option *ngFor="let municipio of ListMunicipios" [value]="municipio.municipioPK['municipio_id']">
                            {{ municipio.nombre }}
                        </option>
                    </select>
                </div>
                
                <div class="col-sm-3">
                    <label class="crear-editar-form requerido">Código postal</label>
                    <input type="text" mask="9{5}" formControlName="codigo_postal" class="form-control"
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['codigo_postal'].errors}">
                </div>
            </div>
        </div>
    </div>
    </form>

    <div *ngIf="canjeable && isChecked && !isMod" class="d-flex justify-content-end">
        <button type="button" class="btn btn-primary ml-4 mr-4 mx-3 mt-4" (click)="onSubmit() " type="submit">Guardar y
            continuar</button>
    </div>