import { Component, EventEmitter, Output } from '@angular/core';
import { DuplicadoPermisoConducirComponent } from '../formulario-duplicado-permiso-conducir/duplicado-permiso-conducir.component';
import { DuplicadoPermisoConducirService } from 'src/app/_services/autoescuela/duplicado-permiso-conducir.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { PresupuestoService } from 'src/app/_services/autoescuela/presupuesto.service';
import { TramitesService } from 'src/app/_services/autoescuela/tramites.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { ClientePresupuesto } from 'src/app/model/autoescuela/clientePresupuesto';
import { Documentos } from 'src/app/model/autoescuela/documentos';
import { EntidadTransferencia } from 'src/app/model/autoescuela/entidad_transferencia';
import { Presupuesto } from 'src/app/model/autoescuela/presupuesto';
import { FileHandle } from './drag-drop.directive';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-visualizar-duplicado-permiso-conducir',
  templateUrl: './visualizar-duplicado-permiso-conducir.component.html',
  styleUrls: ['./visualizar-duplicado-permiso-conducir.component.css']
})
export class VisualizarDuplicadoPermisoConducirComponent {

  @Output() newItemEvent = new EventEmitter<any>();
  
  protected REDSYS_URL = environment.REDSYS_URL;

  isAdmin
  infoPresupuesto:any;

  documentoTramite;

  id: number;

  isEditNewPresu = false
  isLoaded = false
  isExtraInfo = false;


  solicitante: EntidadTransferencia;

  hasPresu = false;

  tipoTramite
  idTramite

  datos = new Map();

  isCargando = false

  detallesPresupuesto = [];
  total = 0;
  iva = 0;
  irpf = 0;
  baseTotal = 0;

  isMod
  isAutoescuela

  selectPresupuesto = false;
  selectedPresupuesto = false;
  generatedPresupuesto = false;
  presupuesto: any = undefined;
  preuspuestoAux: any = undefined;
  nonSelectedPresupuesto: any = undefined;
  presupuestos: any = [];
  documentoPresupuesto

  arrayDocuments = [];
  documentosAux;
  showCalculadora = false;
  firstsearch = true;
  uploadDocuments = false;

  filters: any[] = [];
  sorts: any[] = [];
  
  params;
  firma;

  page = "0";
  size = "100000000";

  mostrarForm = false

  request = {
    filters: this.filters,
    sorts: this.sorts,
    page: this.page,
    size: this.size,
  };

  documentoTrafico;

  estadoFirmaTramite;
  estadoFirmaTrafico;

  constructor(
    private servicioDuplicado: DuplicadoPermisoConducirService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private tramiteService: TramitesService,
    private _tokenStorageService: TokenStorageService,
    private presupuestoService?: PresupuestoService,
    private router?:Router
  ) { }

  ngOnInit(): void {
    if (this._tokenStorageService.getRol() == 3 || this._tokenStorageService.getRol() == 99) {
      this.isAdmin = true;
    }else if(this._tokenStorageService.getRol() == 5 || this._tokenStorageService.getRol() == 6){
      this.isMod = true;
    }else if (this._tokenStorageService.getRol() == 4) {
      this.isAutoescuela = true;
    }
    this.getTransferencia()
  }

  async getTransferencia() {
    this.detallesPresupuesto = [];
    this.total = 0;
    this.iva = 0;
    this.irpf = 0;
    this.baseTotal = 0;

    this.route.params.subscribe((params) => {
      this.id = params["id"];
    })
    this.servicioDuplicado.getDuplId(this.id).subscribe({
      next: (data) => {
        console.log(data)

        if(data==null){
          this.notificationService.error(
            "Error",
            "No tiene acceso a estos datos",
            4000
          );
          this.router.navigate(['/client-autoescuelas-hencok/listado-tramites'])


        }
        this.solicitante = data["solicitante"];
        

        this.tipoTramite = data["tipoTramite"]["id"];
        this.idTramite = data["id"]
        this.documentosAux = data["documentosAdjuntos"];

        if (data["presupuesto"]) {
          this.presupuesto=data["presupuesto"]          
          this.downloadPresupuesto(data["presupuesto"])
        }
        else{
          this.generateMockPresupuesto()
        }

        this.downloadTramiteB64(1);
        this.downloadTramiteB64(2);

        this.getDocumentos()
        
      },
      error: (error) => {
        //console.log(error);
      },
    });
  }

  downloadPresupuesto(presupuesto) {
    this.presupuestoService.downloadJustificanteB64(presupuesto.documento.id_gestor_documental)
      .subscribe((data) => {
        this.hasPresu = true
        this.documentoPresupuesto = data.Result
        this.isLoaded = true
      })
  }
  downloadTramiteB64(idDocumento) {
    this.servicioDuplicado.downloadTramiteB54(this.id, idDocumento)
      .subscribe({
        next: (documento) => {
          switch (idDocumento) {

            case(1):
              this.documentoTramite = documento;
              break;
            case(2):
              this.documentoTrafico = documento;
              break;
            }
        },
        error: (documento) => {
          console.log(documento);

          if(documento.status == 200){
            switch (idDocumento) {

              case(1):
                this.documentoTramite = documento.error.text;
                if(this.documentoTramite){
                  this.tramiteService.checkStateFirma(6, this.id, idDocumento).subscribe({
                    next:(estado) => {
                      this.estadoFirmaTramite = estado;
                    }
                  })
                }
                break;
              case(2):
                this.documentoTrafico = documento.error.text;
                if(this.documentoTrafico){
                  this.tramiteService.checkStateFirma(6, this.id, idDocumento).subscribe({
                    next:(estado) => {
                      this.estadoFirmaTrafico = estado;
                    }
                  })
                }
                break;
              }
          }
        }
      })
  }

  generateMockPresupuesto() {
    let presupuestoInfo;
    this.tramiteService.getPreciosTramite(this.tipoTramite, this.solicitante.provincia).subscribe((data) => {
      presupuestoInfo = data;
      let importe = 0;
        let total = 0;
        if(presupuestoInfo.tasaDgt){
          importe += presupuestoInfo.tasaDgt;
          total += presupuestoInfo.tasaDgt;
        }
        if(presupuestoInfo.tasaGestores1){
          importe += presupuestoInfo.tasaGestores1;
          total += presupuestoInfo.tasaGestores1;
        }
        if(presupuestoInfo.tasaGestores2){
          importe += presupuestoInfo.tasaGestores2;
          total += presupuestoInfo.tasaGestores2;
        }
        
        var detail = {
          concepto: presupuestoInfo.tipoTramite.nombre,
          baseImponible: importe,
          tipoIva: 0,
          tipoIrpf: 0,
          total: total
        }
        if(presupuestoInfo.honorariosGestoria){
          if(total == 0){
            var result = this.generateMockDetalle(presupuestoInfo.tipoTramite.nombre, presupuestoInfo.honorariosGestoria, presupuestoInfo.tipoIva, 0);
          }
          else{
            var result = this.generateMockDetalle("Honorarios gestoría", presupuestoInfo.honorariosGestoria, presupuestoInfo.tipoIva, 0);
          }
          this.total = Number((this.total + result.detail.total).toFixed(3));
          this.iva = Number((this.iva + result.ivaTotal).toFixed(3));
          this.irpf = Number((this.irpf + result.irpfTotal).toFixed(3));
          this.baseTotal = Number((this.baseTotal + result.detail.baseImponible).toFixed(3));
        }
        if(total != 0){
          this.detallesPresupuesto.push(detail);
        }
        this.detallesPresupuesto.push(result.detail);
        
        this.total = Number((this.total + total).toFixed(3));
        this.baseTotal = Number((this.baseTotal + importe).toFixed(3));
  
      this.presupuesto = {
        baseImponible: this.baseTotal,
        importeIva: this.iva,
        importeIrpf: this.irpf,
        total: this.total,
        detalle: this.detallesPresupuesto,
      }
    })

    this.isLoaded = true
    if(this.isAutoescuela){
      this.generatePresupuesto();
    }
  }

  generateMockDetalle(concepto, base, iva, irpf) {
    var total = 0;
    var ivaTotal = 0;
    var irpfTotal = 0;
    if (iva != undefined) {
      ivaTotal = Number((base * iva / 100).toFixed(2));
    }

    if (irpf != undefined) {
      irpfTotal = Number((base * irpf / 100).toFixed(2));
    }

    total = Number((base + ivaTotal + irpfTotal).toFixed(2));
    var detail = {
      concepto: concepto,
      baseImponible: base,
      tipoIva: iva,
      tipoIrpf: irpf,
      total: total
    }

    return {
      detail: detail,
      ivaTotal: ivaTotal,
      irpfTotal: irpfTotal
    }
  }

   onFileChange(event, idDocumento) {
    if (
      event.target.files[0].type == "application/pdf"
    ) {
      const file = event.target.files[0];
      this.convertFile(file).subscribe((base64) => {
        let document = {
          nombre: event.target.files[0].name,
          mime: event.target.files[0].type,
          base64: base64,
        };
        this.cambioDeDocumento(document.base64, idDocumento)
      });
    } else {
      this.notificationService.warning(
        "Informacion",
        "Solo se admiten archivos del tipo: PDF",
        2000
      );
    }
  }
  filesDropped(files: FileHandle[], idDocumento): void {
    if(!this.isMod){
      this.convertFile(files[0].file).subscribe((base64) => {
        let document = {
          nombre: files[0].file.name,
          mime: files[0].file.type,
          base64: base64,
        };
        this.cambioDeDocumento(document.base64, idDocumento)
      });
    }else{
      this.notificationService.warning(
        "Alerta",
        "No puedes adjuntar archivos",
        2000
      );
    }
    
  } 
  cambioDeDocumento(documento, idDocumento) {
    this.servicioDuplicado.saveDocumentoModelo(this.id, documento, idDocumento).subscribe({
      next: (documento) => {
        this.setDocumento(documento, idDocumento)
      },
      error: (documento) => {
        this.setDocumento(documento.error.text, idDocumento)
        this.notificationService.success(
          "Exito",
          "Documento modificado",
          2000
        );
      }
    })

  } 
  setDocumento(documento, idDocumento) {
    if(idDocumento==1){
      this.documentoTramite = documento;
      this.tramiteService.checkStateFirma(6, this.id, idDocumento).subscribe({
        next:(estado) => {
          this.estadoFirmaTramite = estado;
        }
      })
    }
    else{
      this.documentoTrafico = documento;
      this.tramiteService.checkStateFirma(6, this.id, idDocumento).subscribe({
        next:(estado) => {
          this.estadoFirmaTrafico = estado;
        }
      })
    }
  }
  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) =>
      result.next(btoa(event.target.result.toString()));
    return result;
  }
  abrirArchivo(documento) {
    const byteArray = new Uint8Array(atob(documento).split('').map(char => char.charCodeAt(0)));
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url, "_blank")
  }
   emviarAFirmar(idDocumento) {
    this.servicioDuplicado.signMandato(this.id, idDocumento).subscribe({
      next: (documento) => {
        if (documento == null) {
          this.notificationService.error(
            "Error",
            "No se puede firmar, debes adjuntar el archivo modificado",
            4000
          );
        }
      },

      error: (documento) => {
        console.log(documento);

        if (documento.status == 200) {
          this.notificationService.success(
            "Informacion",
            documento.error.text,
            4000
          );
        } else {
          this.notificationService.error(
            "Error",
            "Error al enviar a firmar",
            4000
          );
        }

      }
    })
  } 
  filesDroppedArr(files: FileHandle[]) {
    this.convertFile(files[0].file).subscribe((base64) => {
      let document: Documentos = {
        nombre: files[0].file.name,
        mime: files[0].file.type,
        base64: base64,
        isNew: true,
      };
      var isSameName = false
      this.arrayDocuments.forEach((doc) => {
        if (doc.nombre == document.nombre) {
          isSameName = true;
        }

      })
      if (!isSameName) {
        this.arrayDocuments.push(document)
      } else {
        this.notificationService.error(
          "Error",
          "Ya existe un documento con ese nombre.\n Elimine antes el existente",
          4000
        );
      }

    })
  }
  onFileChangeArr(event) {
    if (
      event.target.files[0].type == "application/pdf"
    ) {
      const file = event.target.files[0];
      this.convertFile(file).subscribe((base64) => {
        let document = {
          nombre: event.target.files[0].name,
          mime: event.target.files[0].type,
          base64: base64,
          isNew: true,
        };
        var isSameName = false
        this.arrayDocuments.forEach((doc) => {
          if (doc.nombre == document.nombre) {
            isSameName = true;
          }

        })
        if (!isSameName) {
          this.arrayDocuments.push(document)
        } else {
          this.notificationService.error(
            "Error",
            "Ya existe un documento con ese nombre.\n Elimine antes el existente",
            4000
          );
        }
      });
    } else {
      this.notificationService.warning(
        "Informacion",
        "Solo se admiten archivos del tipo: PDF",
        2000
      );
    }
  }

  removeFile(index: number, idNextcloud?): void {
    if (idNextcloud != null) {
      this.tramiteService.deleteDocumento(idNextcloud).subscribe()
    }
    this.arrayDocuments.splice(index, 1);
    this.notificationService.success(
      "Exito",
      "Documento borrado",
      2000
    );


  }

  saveDocuments(documentos) {
    this.uploadDocuments = true;
    console.log(documentos)
    documentos.forEach(element => {

      if (element.isNew) {
        this.tramiteService.saveDocumentos(this.id, this.tipoTramite, element).subscribe({
          next: (documento) => {
          },
          error: (documento) => {
            console.log(documento)
            if(documento.status === 200){
              this.getTransferencia();
              this.uploadDocuments = false;
              this.notificationService.success(
                "Exito",
                "Documentos modificados",
                2000
              );
            }
            else{
              this.uploadDocuments = false;
              this.notificationService.error(
                "Error",
                "No se han podido guardar los documentos",
                2000
              );
            }
          }
        })
      }
    })
  }
  getDocumentos() {
    this.arrayDocuments = []
    this.documentosAux.forEach(element => {

      console.log(this.documentosAux);
      console.log(element);

      if (!element["idViafirma"]) {

        this.tramiteService.getDocumentos(element["idNextcloud"]).subscribe({
          next: (documento) => {
            let document: Documentos = {
              nombre: documento["nombre"],
              base64: documento["base64"],
              isNew: false,
              estado: element["estadoDocumentosAdjunto"]["id"],
              idNextcloud: element["idNextcloud"]
            };
            this.arrayDocuments.push(document)
          },
          error: (documento) => {

            console.log(documento)
            let document: Documentos = {
              nombre: documento["nombre"],
              base64: documento["base64"],
              isNew: false,
              estado: element["estadoDocumentosAdjunto"]["id"],
              idNextcloud: element["idNextcloud"]
            };

            this.arrayDocuments.push(document)
          }
        })
      }

    });
    console.log(this.arrayDocuments);
  }
  changeEstadoDocumento(idNextcloud, idEstado) {
    this.tramiteService.changeStateDocumento(idNextcloud, idEstado).subscribe({
      next: () => {
        this.getTransferencia();
      }
    })
  }

  startPresupuestoList(persona) {
    this.filters = [];
    this.doFilter(persona.dni, 'cliente.dni', 'LIKE', 'STRING');
    this.doFilter(persona.nombre, 'cliente.nombre', 'LIKE', 'STRING');

    this.doFilter("ACEPTADO", 'estado.nombre', 'LIKE', 'STRING');
  }

  showCalculadoraItp() {
    this.showCalculadora = true;
  }

  showListaPresupuesto() {
    if (this.firstsearch) {
      this.startPresupuestoList(this.solicitante);
    }
    this.preuspuestoAux=this.presupuesto;
    this.firstsearch = false;
    this.selectPresupuesto = true;
  }

  notShowCalculadoraItp() {
    this.showCalculadora = false;
  }

  closeListaPresupuesto() {
    this.selectPresupuesto = false;
    this.presupuesto=this.preuspuestoAux;
  }

  setPresupuesto(presupuesto: any) {
    console.log(presupuesto)
    this.selectPresupuesto = false;
    if (presupuesto != undefined) {
      this.presupuesto = presupuesto;
      this.selectedPresupuesto = true;
    }
    this.notShowCalculadoraItp();

  }


  getDateToFilter(fechas) {
    var fechaString = new Date(fechas);
    let month = "" + (fechaString.getMonth() + 1);
    let day = "" + fechaString.getDate();
    const year = fechaString.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day, month, year].join("/");
  }

  async doFilter(value, type, operator, field_type) {

    if (value != "") {
      let index = this.filters.findIndex((item) => item.key == type);
      let newFilter = {
        key: type,
        operator: operator,
        field_type: field_type,
        value: value,
      };
      if (index != -1) {
        this.filters[index] = newFilter;
      } else {
        this.filters.push(newFilter);
      }
    } else {
      this.filters = this.filters.filter((item) => item.key !== type);
    }
    console.log("los filtros son", this.filters);
    await this.getPresupuestos();
  }

  async getPresupuestos() {
    let rol = this._tokenStorageService.getRol();

    if (rol == 4) {
      let filterUser = {
        key: "usuario.id",
        operator: "EQUAL",
        field_type: "LONG",
        value: this._tokenStorageService.getId(),
      };
      this.filters.push(filterUser);
    }
    this.request = {
      filters: this.filters,
      sorts: this.sorts,
      page: this.page,
      size: this.size,
    };
    this.presupuestoService
      .getPresupuestoFilter(this.request)
      .subscribe((data: any) => {
        this.presupuestos = data.pageList;
        console.log(data.pageList);
        if (this.presupuestos.length > 0) {
          this.presupuesto = this.presupuestos.at(0);
        }
      });
  }

  
  generatePresupuesto() {
    this.isCargando = true;
    var codigo
    this.presupuestoService.getCodigo(new Date().getFullYear()).subscribe((data) => {
      codigo = data["codigo"]
      var presupuesto = new Presupuesto(
        new Date(),
        codigo,
        new Date(new Date().setDate(new Date().getDate() + 30)),
        "",
        this.baseTotal
      )
      var clientepresu = new ClientePresupuesto(this.solicitante)
      this.presupuestoService.create(presupuesto, this.detallesPresupuesto, clientepresu).subscribe((data) => {
        this.tramiteService.vincularPresu(this.idTramite, this.tipoTramite, data["id"]).subscribe((data) => {
          this.downloadPresupuesto(data["presupuesto"])
          this.isCargando = false
        }

        )
      })
    }

    )



  }

  setMostrarFormulario(bool) {
    if (this.mostrarForm == true) {
      this.newItemEvent.emit(false);
    }
    this.mostrarForm = bool;

  }

  asignPresupuesto(){
    this.isCargando = true;
    this.tramiteService.vincularPresu(this.idTramite, this.tipoTramite, this.presupuesto.id).subscribe((data) => {
      this.downloadPresupuesto(data["presupuesto"])
      this.isCargando = false;
    }

    )
  }

  setEditarChild(){
    this.presupuestoService.getCodigo(new Date().getFullYear()).subscribe((data) => {
      var codigo = data["codigo"]
      var presupuesto = new Presupuesto(
        new Date(),
        codigo,
        new Date(new Date().setDate(new Date().getDate() + 30)),
        "",
        this.baseTotal
      )
      this.infoPresupuesto = {
        presupuesto: presupuesto,
        detalle: this.presupuesto.detalle,
        isTransferencia: true,
        cliente: this.solicitante
      }
      this.isEditNewPresu = !this.isEditNewPresu;
      this.mostrarForm=!this.mostrarForm;
    })
  }

  setMostrarFormularioChild(value) {
    console.log(value)
    if(value.id!=undefined){
      this.presupuesto=value;
      this.setMostrarFormulario(false);
      this.asignPresupuesto()
      this.isEditNewPresu = false;
    }
    if (value == false) {
      this.setMostrarFormulario(false);
    }

    //this.newItemEvent.emit(false);

  }
  onChangePresupuesto(event: Event) {
    this.presupuesto = this.presupuestos[Number((event.target as HTMLInputElement).value)];
    console.log(this.presupuesto)
  }

  closePage(event){
    this.router.navigate(["client-autoescuelas-hencok/listado-tramites"]);
  }

  goPago() {
    const urlPresu = window.location.origin + '/#/' + window.location.href.replace(window.location.origin + '/#/', '').split('/')[0] + '/presupuestos';
    window.history.pushState(null, urlPresu, urlPresu);
    if(this.hasPresu){
      this.presupuestoService.tryPayment(this.presupuesto.id, window.location.href).subscribe({
        next: (data) => {
          this.params = data.params
          this.firma = data.firma
  
          const form = document.createElement('form');
          form.method = 'POST';
          form.action = this.REDSYS_URL;
  
          // Agregar el HTML como un campo oculto en el formulario
          const htmlInput = document.createElement('input');
          htmlInput.type = 'hidden';
          htmlInput.name = 'Ds_SignatureVersion';
          htmlInput.value = "HMAC_SHA256_V1";
          form.appendChild(htmlInput);
  
          const htmlInput1 = document.createElement('input');
          htmlInput1.type = 'hidden';
          htmlInput1.name = 'Ds_MerchantParameters';
          htmlInput1.value = this.params;
          form.appendChild(htmlInput1);
  
          const htmlInput2 = document.createElement('input');
          htmlInput2.type = 'hidden';
          htmlInput2.name = 'Ds_Signature';
          htmlInput2.value = this.firma;
          form.appendChild(htmlInput2);
  
  
          document.body.appendChild(form);
          form.submit();
  
        }
      })
    }
    
  };
  sendTramite(){
    this.tramiteService.changeStateTramite(6,this.idTramite).subscribe({
      next: (data) => {
        this.notificationService.success('Exito', "Trámite enviado", 3000);
      },
      error:(err) => {
          
      },
    });
  }
}
